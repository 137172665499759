export const lazyRetry = (importFunction, retries = 3, delay = 1000) => {
  return new Promise((resolve, reject) => {
    const attempt = (retryCount) => {
      importFunction()
        .then(resolve)
        .catch((error) => {
          if (retryCount <= 0) {
            reject(error);
          } else {
            setTimeout(() => attempt(retryCount - 1), delay);
          }
        });
    };

    attempt(retries);
  });
};
