import { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "@/common/RouteWithLayout";
import { Minimal as MinimalLayout } from "@/layouts";
import { Main as MainLayout } from "@/layouts";
import { Custom as CustomLayout } from "@/layouts";
import { lazyRetry } from "./utils/utils";
import OverlayIndicator from "./common/OverlayIndicator";

const MainView = lazy(() => lazyRetry(() => import("./views/Main")));
const GuestView = lazy(() => lazyRetry(() => import("./views/GuestDashboard")));
const GuestCheckInView = lazy(() =>
  lazyRetry(() => import("./views/GuestCheckIn"))
);
const GuestCheckOutView = lazy(() =>
  lazyRetry(() => import("./views/GuestCheckOut"))
);
const EmployeeSignInView = lazy(() =>
  lazyRetry(() => import("./views/EmployeeSignIn"))
);
const CreatePinView = lazy(() => lazyRetry(() => import("./views/CreatePin")));
const DashboardView = lazy(() => lazyRetry(() => import("./views/Dashboard")));
const HoursView = lazy(() => lazyRetry(() => import("./views/Hours")));
const PlanningView = lazy(() => lazyRetry(() => import("./views/Planning")));
const PlannedHoursView = lazy(() =>
  lazyRetry(() => import("./views/PlannedHours"))
);
const ItemsView = lazy(() => lazyRetry(() => import("./views/Items")));
const AttendanceView = lazy(() =>
  lazyRetry(() => import("./views/Attendance"))
);
const NotFoundView = lazy(() => lazyRetry(() => import("./views/NotFound")));
const AddUserView = lazy(() => lazyRetry(() => import("./views/AddUser")));
const CheckListView = lazy(() => lazyRetry(() => import("./views/CheckList")));
const CheckListDetailView = lazy(() =>
  lazyRetry(() => import("./views/CheckList/detail"))
);
const AddCheckListView = lazy(() =>
  lazyRetry(() => import("./views/CheckList/addCheckList"))
);
const LogoutView = lazy(() => lazyRetry(() => import("./views/Logout")));
const ProjectSettingView = lazy(() =>
  lazyRetry(() => import("./views/DeviceSetting"))
);
const KioskModeView = lazy(() => lazyRetry(() => import("./views/KioskMode")));

const Routes = () => {
  return (
    <Suspense fallback={<OverlayIndicator isLoading={true} />}>
      <Switch>
        {/*<Redirect exact from="/" to="/main" />*/}
        <RouteWithLayout
          component={MainView}
          exact
          layout={CustomLayout}
          path="/token/:token"
        />
        <RouteWithLayout
          component={GuestView}
          exact
          layout={MinimalLayout}
          path="/guest"
        />
        <RouteWithLayout
          component={GuestView}
          exact
          layout={MinimalLayout}
          path="/guest"
        />
        <RouteWithLayout
          component={GuestCheckInView}
          exact
          layout={MinimalLayout}
          path="/guest/check-in"
        />
        <RouteWithLayout
          component={GuestCheckOutView}
          exact
          layout={MinimalLayout}
          path="/guest/check-out"
        />
        <RouteWithLayout
          component={EmployeeSignInView}
          exact
          layout={CustomLayout}
          path="/login"
        />
        <RouteWithLayout
          component={CreatePinView}
          exact
          layout={MainLayout}
          path="/employee/create-pin"
          title="Create PIN"
        />
        <RouteWithLayout
          component={DashboardView}
          exact
          layout={MainLayout}
          path="/dashboard"
          title="Dashboard"
        />
        <RouteWithLayout
          component={HoursView}
          exact
          layout={CustomLayout}
          path="/hours"
          title="Hours"
        />
        <RouteWithLayout
          component={PlanningView}
          exact
          layout={CustomLayout}
          path="/planning"
          title="Planning"
        />
        <RouteWithLayout
          component={PlannedHoursView}
          exact
          layout={CustomLayout}
          path="/planned-hours"
          title="Planned hours"
        />
        <RouteWithLayout
          component={ItemsView}
          exact
          layout={CustomLayout}
          path="/items"
          title="Items"
        />
        <RouteWithLayout
          component={AttendanceView}
          exact
          layout={CustomLayout}
          path="/attendance"
          title="Attendance"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <RouteWithLayout
          component={AddUserView}
          exact
          layout={MainLayout}
          path="/employee/add-user"
          title="Add user"
        />
        <RouteWithLayout
          component={CheckListView}
          exact
          layout={CustomLayout}
          path="/hmsk/checkList"
          title="Check list"
        />
        <RouteWithLayout
          component={CheckListDetailView}
          exact
          layout={CustomLayout}
          path="/hmsk/checkListDetail/:id"
          title="Checklist details"
        />
        <RouteWithLayout
          component={AddCheckListView}
          exact
          layout={CustomLayout}
          path="/hmsk/addCheckList/"
          title="Your checklists"
        />
        <RouteWithLayout
          component={LogoutView}
          exact
          layout={MainLayout}
          path="/logout"
          title="Sign out"
        />
        <RouteWithLayout
          component={ProjectSettingView}
          exact
          layout={MinimalLayout}
          path="/login/projectsetting"
          title="Project Setting"
        />
        <RouteWithLayout
          component={KioskModeView}
          exact
          layout={MinimalLayout}
          path="/kiosk/:token"
        />
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
